<template>
  <v-footer padless>
      <v-card
        flat
        tile
        width="100%"
        dark
        class="text-center"
      >
        <v-card-text>
          <v-tooltip
            top
            v-for="medium in media"
            :key="medium.icon"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-4"
                icon
                v-bind="attrs"
                v-on="on"
                :href="medium.href"
                target="_blank"
              >
                <v-icon size="24px">
                  {{ medium.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ medium.text }}</span>
          </v-tooltip>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Felix Schuler</strong>
        </v-card-text>
      </v-card>
    </v-footer>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    media: [
      { icon: 'mdi-facebook', text: 'Felix Schuler', href: 'https://www.facebook.com/felix.schuler.3954' },
      { icon: 'mdi-github', text: 'felixschuler', href: 'https://github.com/felixschuler' },
      { icon: 'mdi-instagram', text: 'felix.slr', href: 'https://www.instagram.com/felix.slr' },
      { icon: 'mdi-twitter', text: 'Feylyx', href: 'https://twitter.com/Feylyx' },
    ],
  }),
};
</script>
