import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/web',
    name: 'Web',
    component: () => import('../views/Web.vue'),
  },
  {
    path: '/designs',
    name: 'Designs',
    component: () => import('../views/Designs.vue'),
  },
  {
    path: '/*',
    name: '404',
    component: () => import('../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
