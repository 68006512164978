<!--eslint-disable max-len-->
<template>
  <v-app-bar
    app
    dark
  >
    <v-container class="d-flex">
      <v-row justify="center">
        <v-col cols="12" xl="8" class="d-flex">
          <v-img
            alt="Logo"
            class="shrink mr-2"
            contain
            :src="require('../assets/images/deer-logo-white.svg')"
            transition="scale-transition"
            width="40"
          />

          <v-toolbar-title class="title align-self-center hidden-xs-only">
            Felix' Portfolio
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <router-link v-for="link in links" :key="link.text" :to="{ path: link.href }" replace class="text-decoration-none mx-1 hidden-sm-and-down my-auto">
            <v-btn
              text
            >
              <v-icon left>{{ link.icon }}</v-icon>
              <span>{{ link.text }}</span>
            </v-btn>
          </router-link>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                class="mx-1 my-auto"
              >
                <v-icon>mdi-translate</v-icon>
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-subheader>{{ $t('appbar.language') }}</v-subheader>
              <v-list-item-group
                v-model="selectedLanguage"
                mandatory
                color="purple"
              >
                <v-list-item
                  v-for="(language, i) in languages"
                  :key="i"
                  @click="setLocale(language.name)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-uppercase" v-html="parsedEmoji(language.name) + language.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>

          <v-menu
            bottom
            left
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
                class="hidden-md-and-up"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item v-for="link in links" :key="link.text" link :to="{ path: link.href }" replace color="purple">
                <v-list-item-title>
                  <v-icon left>{{ link.icon }}</v-icon>
                  <span>{{ link.text }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<style>
  img.emoji {
    height: 1.2em;
    width: 1.2em;
    margin: 0 .25em;
    vertical-align: -.25em;
  }
</style>

<script>
import twemoji from 'twemoji';

export default {
  name: 'AppBar',
  data() {
    return {
      links: [
        {
          text: 'Home',
          icon: 'mdi-home',
          href: '/',
        },
        {
          text: 'Web-based',
          icon: 'mdi-desktop-classic',
          href: '/web',
        },
        {
          text: 'Designs',
          icon: 'mdi-draw',
          href: '/designs',
        },
      ],
      selectedLanguage: 0,
      languages: [
        {
          name: 'en',
          icon: '🇺🇸',
        },
        {
          name: 'de',
          icon: '🇦🇹',
        },
      ],
    };
  },
  methods: {
    parsedEmoji(string) {
      switch (string) {
        case 'de':
          return twemoji.parse('🇦🇹');
        default:
          return twemoji.parse('🇺🇸');
      }
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
    },
  },
};
</script>
